import type { Api } from '@cocoonspace/types/shared/api'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'

import { defineEndpoint } from '../utils/define-endpoint'

export interface DeleteAccountUser {
	uriParams: Api.Operations['deleteAccountUser']['parameters']['path']
	response: Api.Operations['deleteAccountUser']['responses']['204']
}

export const deleteAccountUser = (
	axiosInstance: AxiosInstance,
	overideConfig?: AxiosRequestConfig,
) =>
	defineEndpoint<DeleteAccountUser>(
		{
			method: 'delete',
			url: '/account/users/{userId}',
			...overideConfig,
		},
		axiosInstance,
	)
