import { cocoonPrivateRoutes } from '@cocoonspace/shared/config/cocoon-private-routes'
import { useAuth } from '@cocoonspace/shared/domains/auth/hooks/use-auth.hook'
import { useAuthDialogStore } from '@cocoonspace/shared/domains/auth/stores/use-auth-dialog-store.hook'
import { useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

export const AuthLayout = ({ children }: { children: JSX.Element }) => {
	const queryClient = useQueryClient()
	const router = useRouter()
	const { isAuth, isLoading } = useAuth()
	const openDialog = useAuthDialogStore((state) => state.openDialog)

	const privateRoute = cocoonPrivateRoutes.find((route) =>
		router.route.startsWith(route.path),
	)

	useEffect(() => {
		if (!isLoading && !isAuth && !!privateRoute) {
			if (privateRoute.mode === 'redirect') {
				router.push('/')
			}

			openDialog()
		}
	}, [isLoading, privateRoute, isAuth, router, openDialog])

	useEffect(() => {
		queryClient.invalidateQueries()
	}, [isAuth])

	return (
		<>
			{!!privateRoute && (isLoading || !isAuth) ? (
				<div className='min-h-[80vh]' />
			) : (
				children
			)}
		</>
	)
}
