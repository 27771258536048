export const cocoonPrivateRoutes = [
	{ path: '/booking', mode: 'redirect' },
	{ path: '/favorites', mode: 'redirect' },
	{ path: '/my-events', mode: 'redirect' },
	{ path: '/my-account', mode: 'redirect' },
	{ path: '/event/extras', mode: 'redirect' },
	{ path: '/dashboard', mode: 'redirect' },
	{ path: '/payment', mode: 'dialog' },
	{ path: '/overstays', mode: 'redirect' },
]
