export const ROUTES_WITHOUT_FOOTER = [
	'/booking',
	'/booking/[bookingId]/extras',
	'/booking/[bookingId]/payment',
	'/booking/[bookingId]/confirmation',
	'/event/[eventId]/extras',
	'/[zone]',
	'/desks',
	'/desks/[zone]',
	'/event/[eventId]',
	'/event/extras/[bookingId]',
	'/onboarding',
	'/onboarding/email',
	'/onboarding/personal-infos',
	'/onboarding/email-validation',
]
