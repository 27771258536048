import { APP_CONFIG } from '@cocoonspace/shared/config'
import { facebookConfig } from '@cocoonspace/shared/config/facebook-config'
import { twitterConfig } from '@cocoonspace/shared/config/twitter-config'
import { useTranslation } from 'next-i18next'
import Head from 'next/head'
import { useRouter } from 'next/router'
import type { PropsWithChildren } from 'react'

interface SeoTagsProps {
	title: string
	description: string
	imageUrl?: string
}

const twicDomain = process.env.NEXT_PUBLIC_TWICPICS_DOMAIN
const twicPath = process.env.NEXT_PUBLIC_TWICPICS_PATH

const getFacebookMetas = ({
	title,
	description,
	imageUrl,
	url,
}: Record<string, string>) => {
	const defaultImagePath = `${twicDomain}/${twicPath}${facebookConfig.metaImage.url}?twic=v1/resize=${facebookConfig.metaImage.width}`

	return [
		{ property: 'fb:app_id', content: process.env.NEXT_PUBLIC_FACEBOOK_APPID },
		{ property: 'og:locale', content: 'fr_FR' },
		{ property: 'og:type', content: 'website' },
		{ property: 'og:url', content: url },
		{ property: 'og:site_name', content: process.env.NEXT_PUBLIC_NAME },
		{ property: 'og:title', content: title },
		{ property: 'og:description', content: description },
		{
			property: 'og:image',
			content: imageUrl || defaultImagePath,
		},
		{ property: 'og:image:type', content: facebookConfig.metaImage.type },
		{
			property: 'og:image:width',
			content: `${facebookConfig.metaImage.width}`,
		},
		{
			property: 'og:image:height',
			content: `${facebookConfig.metaImage.height}`,
		},
	]
}

const getTwitterMetas = ({
	title,
	description,
	imageUrl,
}: Record<string, string>) => {
	const defaultTitterImg = `${twicDomain}/${twicPath}${twitterConfig.metaImage.url}?twic=v1/resize=${twitterConfig.metaImage.width}`

	return [
		{ name: 'twitter:card', content: 'summary_large_image' },
		{ name: 'twitter:site', content: twitterConfig.handleName },
		{ name: 'twitter:title', content: title },
		{ name: 'twitter:description', content: description },
		{
			name: 'twitter:image',
			content: imageUrl || defaultTitterImg,
		},
	]
}

export const SeoTags = ({
	title,
	description,
	imageUrl,
	children,
}: PropsWithChildren<SeoTagsProps>) => {
	const { t, i18n } = useTranslation()
	const { asPath } = useRouter()

	return (
		<Head>
			<title>{i18n.exists(title) ? (t(title) as string) : title}</title>

			<meta
				name='description'
				content={
					i18n.exists(description) ? (t(description) as string) : description
				}
			/>

			<link
				rel='canonical'
				href={`${APP_CONFIG.appUrl}${
					(asPath === '/' ? '' : asPath).split('?')[0]
				}`}
			/>

			{/* FB */}

			{getFacebookMetas({
				title,
				description,
				imageUrl: imageUrl || '',
				url: `${APP_CONFIG.appUrl}${asPath}`,
			}).map((meta) => (
				<meta
					key={meta.property}
					property={meta.property}
					content={meta.content}
				/>
			))}

			{getTwitterMetas({
				title,
				description,
				imageUrl: imageUrl || '',
			}).map((meta) => (
				<meta
					key={meta.name}
					name={meta.name}
					content={meta.content}
				/>
			))}

			{children}
		</Head>
	)
}
