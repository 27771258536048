import type { Api } from '@cocoonspace/types/shared/api'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'

import { defineEndpoint } from '../utils/define-endpoint'

// export interface SpacePrices {
// 	day: string
// 	price_hour?: number
// 	price_day?: number
// 	closed?: boolean
// 	price_hour_discounted?: number
// 	rebate_codes?: string[]
// }
export interface GetPrices {
	uriParams: Api.Operations['getSpacePrices']['parameters']['path']
	params: Api.Operations['getSpacePrices']['parameters']['query']
	response: Api.Operations['getSpacePrices']['responses']['200']['content']['application/json']
}

export const getPrices = (
	axiosInstance: AxiosInstance,
	overideConfig?: AxiosRequestConfig,
) =>
	defineEndpoint<GetPrices>(
		{
			method: 'get',
			url: '/spaces/{spaceId}/prices',
			...overideConfig,
		},
		axiosInstance,
	)
