import type { AxiosInstance } from 'axios'

import { downloadInvoices } from '../endpoints/download-invoices'
import { downloadInvoice } from './../endpoints/download-invoice'
import { getInvoice } from './../endpoints/get-invoice'
import { getInvoiceTotals } from './../endpoints/get-invoice-totals'
import { listInvoices } from './../endpoints/list-invoices'

export const invoicesModule = (axiosInstance: AxiosInstance) => ({
	download: downloadInvoice(axiosInstance),
	downloadMultiple: downloadInvoices(axiosInstance),
	getTotals: getInvoiceTotals(axiosInstance),
	getAll: listInvoices(axiosInstance),
	getOne: getInvoice(axiosInstance),
})
