import type { GetBooking } from '@cocoonspace/sdk-js-legacy/endpoints/get-booking'
import type { UpdateBooking } from '@cocoonspace/sdk-js-legacy/endpoints/update-booking'
import type { ValidateBooking } from '@cocoonspace/sdk-js-legacy/endpoints/validate-booking'
import type { Space } from '@cocoonspace/sdk-js/types/space'
import type { Api } from '@cocoonspace/types/shared/api'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useAuth } from '../../domains/auth/hooks/use-auth.hook'
import { cleanQueryKeyFactories } from '../../utils/clean-query-key-factories'
import { dateTimeUtils } from '../../utils/date-time-utils'
import { useCocoonApi } from './use-cocoon-api.hook'

export namespace BookingHooksCtx {
	export interface detail {
		bookingId: GetBooking['uriParams']['bookingId']
	}
}

/**
 * Query keys
 */
export const BOOKING_KEYS = cleanQueryKeyFactories({
	base: ['booking'] as const,
	detail: ({ bookingId }: BookingHooksCtx.detail) =>
		[...BOOKING_KEYS.base, 'detail', bookingId] as const,
	findOrCreate: () => [...BOOKING_KEYS.base, 'findOrCreate'] as const,
} as const)

export const useBookingDetails = (
	bookingId: BookingHooksCtx.detail['bookingId'],
	params?: any,
) => {
	const { isAuth, isLoading } = useAuth()
	const { getOne } = useCocoonApi('bookings')

	return useQuery({
		queryKey: BOOKING_KEYS.detail({ bookingId }),
		queryFn: () =>
			getOne({ uriParams: { bookingId }, params }).then((res) => res.data.data),
		enabled: !isLoading && isAuth && !!bookingId,
	})
}

export const useBookingDetailsUpdate = () => {
	const { updateOne } = useCocoonApi('bookings')

	return useMutation({
		mutationFn: ({
			bookingId,
			data,
			params,
		}: {
			bookingId: UpdateBooking['uriParams']['bookingId']
			data: UpdateBooking['body']
			params: any
		}) =>
			updateOne({ uriParams: { bookingId }, data, params }).then(
				(res) => res.data.data,
			),
	})
}

export const useBookingValidate = () => {
	const { validate } = useCocoonApi('bookings')

	return useMutation({
		mutationFn: ({
			bookingId,
			...data
		}: ValidateBooking['body'] & ValidateBooking['uriParams']) =>
			validate({ uriParams: { bookingId }, data }).then((res) => res.data.data),
		onSuccess: () => {},
	})
}

export const useBookingCreate = () => {
	const { createOne } = useCocoonApi('bookings')

	return useMutation({
		mutationFn: (data: any) => createOne({ data }).then((res) => res.data.data),
	})
}

export const useCreateMultidateBooking = () => {
	const { listAllAvailabilities } = useCocoonApi('spaces')
	const { createOne: createEvent } = useCocoonApi('events')
	const { mutateAsync: createBooking } = useBookingCreate()

	return useMutation({
		mutationFn: async ({
			bookingOptions,
			space,
		}: { bookingOptions: Date[]; space: Space }) => {
			const payload = bookingOptions.map((date) => {
				const day = dateTimeUtils(date).locale('en').format('ddd').toLowerCase()

				const spaceHours =
					space.hours[day as keyof typeof space.hours]?.open?.[0]

				return {
					day: dateTimeUtils(date).customFormat('dateTech'),
					start: `${spaceHours?.from}`,
					end: `${spaceHours?.to}`,
					space: space.id,
					type: 'meeting',
				}
			})

			const avails = await listAllAvailabilities({
				data: payload as Api.Schemas['AvailabilityRequest'][],
			}).then((res) => res.data.data)

			const unavailable = avails.filter(({ available }) => !available)

			if (unavailable.length) {
				return Promise.reject({
					message: 'unavailable',
					data: unavailable,
				})
			}

			const booking = await createBooking({})

			const events = payload.map((item) => {
				return createEvent({
					data: {
						...item,
						booking: booking?.id,
					} as Api.Schemas['EventCreateRequest'],
				}).then((res) => res.data.data)
			})

			return Promise.all(events).then((events) => ({
				booking,
				events,
			}))
		},
	})
}
