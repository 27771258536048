import type { Api } from '@cocoonspace/types/shared/api'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'

import { defineEndpoint } from '../utils/define-endpoint'

export interface AddFavorite {
	uriParams: Api.Operations['addFavorite']['parameters']['path']
	response: Api.Operations['addFavorite']['responses']['204']
}

export const addFavorite = (
	axiosInstance: AxiosInstance,
	overideConfig?: AxiosRequestConfig,
) =>
	defineEndpoint<AddFavorite>(
		{
			method: 'put',
			url: '/me/favorites/{spaceId}',
			...overideConfig,
		},
		axiosInstance,
	)
