import { type PropsWithChildren, createContext, useContext } from 'react'

interface ConfirmCardOptions {
	cardElement: any
	billingDetails: {
		name: string
	}
	metadata?: {
		title: string
	}
}

export class PaymentError {
	constructor(public error: { message?: string }) {}
}

// https://stripe.com/docs/api/payment_intents
// enhance if needed, update the implementations
interface PaymentIntent {
	id: string
	_res: unknown
}

export type PaymentIntentResult =
	| { paymentIntent: PaymentIntent; error?: undefined }
	| { paymentIntent?: undefined; error: PaymentError }

interface SetupIntent {
	id: string
	_res: unknown
}

export type SetupIntentResult =
	| { setupIntent: SetupIntent; error?: undefined }
	| { setupIntent?: undefined; error: PaymentError }

export interface PaymentService {
	createPaymentMethod: (
		continuationKey: string,
		options: ConfirmCardOptions,
	) => Promise<SetupIntentResult>
	confirmCardPayment: (continuationKey: string) => Promise<PaymentIntentResult>
}

interface PaymentContext {
	service: () => PaymentService
}

const paymentContext = createContext({} as PaymentContext)

interface PaymentProviderProps {
	service: () => PaymentService
}

export const usePayment = () => {
	const { service } = useContext(paymentContext)

	return service()
}

export const PaymentProvider = ({
	children,
	service,
}: PropsWithChildren<PaymentProviderProps>) => {
	return (
		<paymentContext.Provider value={{ service }}>
			{children}
		</paymentContext.Provider>
	)
}
