import type { AxiosRequestConfig } from 'axios'

import type { SdkConfig } from '../../lib'

export const authTokenInterceptor =
	(sdkConfig: SdkConfig) => async (config: AxiosRequestConfig) => {
		if (sdkConfig.getAuthToken) {
			const token = await sdkConfig.getAuthToken(config)

			if (token) {
				config.headers = config.headers || {}
				config.headers['Authorization'] = `Bearer ${token}`
			}
		}

		return config
	}
