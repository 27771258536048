import type { Api } from '@cocoonspace/types/shared/api'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'

import { defineEndpoint } from '../utils/define-endpoint'

export interface GetInvoiceFor {
	uriParams: Api.Operations['getInvoiceFor']['parameters']['path']
	response: Api.Operations['getInvoiceFor']['responses']['200']
}

export const getInvoiceFor = (
	axiosInstance: AxiosInstance,
	overideConfig?: AxiosRequestConfig,
) =>
	defineEndpoint<GetInvoiceFor>(
		{
			method: 'get',
			url: '/invoices/for/{eventId}',
			...overideConfig,
		},
		axiosInstance,
	)
