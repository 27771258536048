import {
	type AuthError,
	FacebookAuthProvider,
	GoogleAuthProvider,
	getAuth,
	signInWithCredential,
	signOut,
} from 'firebase/auth'
import { useMemo } from 'react'
import {
	useAuthState,
	useCreateUserWithEmailAndPassword,
	useSendPasswordResetEmail,
	useSignInWithApple,
	useSignInWithEmailAndPassword,
	useSignInWithFacebook,
	useSignInWithGoogle,
} from 'react-firebase-hooks/auth'
import { create } from 'zustand'

import { useQueryClient } from '@tanstack/react-query'
import { useCocoonApi } from '../../../hooks/cocoon-api'

export type AuthProvider = 'password' | 'google' | 'apple' | 'facebook'

export interface EmailPassPayload {
	email: string
	password: string
}

type ErrorCredsState = {
	errorCreds: AuthError | undefined
	setErrorCreds: (error: AuthError | undefined) => void
}

export const useErrorCredsStore = create<ErrorCredsState>((set) => ({
	errorCreds: undefined,
	setErrorCreds: (errorCreds) => set({ errorCreds }),
}))

const firebaseAuth = getAuth()

export const useAuth = () => {
	const [fireAuthUser, loading, error] = useAuthState(firebaseAuth)
	const { me } = useCocoonApi('users')
	const errorCreds = useErrorCredsStore((state) => state.errorCreds)
	const setErrorCreds = useErrorCredsStore((state) => state.setErrorCreds)
	const queryClient = useQueryClient()

	const [createUserWithEmailAndPassword, , , errorRegister] =
		useCreateUserWithEmailAndPassword(firebaseAuth)

	const [sendPasswordResetEmail] = useSendPasswordResetEmail(firebaseAuth)

	const [signInWithEmailAndPassword, , , errorPassword] =
		useSignInWithEmailAndPassword(firebaseAuth)

	const [signInWithGoogle, , , errorGoogle] = useSignInWithGoogle(firebaseAuth)
	const [signInWithApple, , , errorApple] = useSignInWithApple(firebaseAuth)

	const [signInWithFacebook, , , errorFacebook] =
		useSignInWithFacebook(firebaseAuth)

	const loginWithCredentials = (provider: AuthProvider, payload: any) => {
		let credentials: any

		if (provider === 'google') {
			credentials = GoogleAuthProvider.credential(payload)
		} else if (provider === 'apple') {
			// credentials = AppleAuthProvider.credential(payload)
		} else if (provider === 'facebook') {
			credentials = FacebookAuthProvider.credential(payload)
		}

		if (credentials) {
			return signInWithCredential(firebaseAuth, credentials)
				.then(() => setErrorCreds(undefined))
				.catch(setErrorCreds)
		}
	}

	const logout = () => signOut(firebaseAuth)

	const loginWithProviders = async (
		provider: AuthProvider,
		payload?: EmailPassPayload,
	) => {
		if (provider === 'google') {
			return signInWithGoogle()
		} else if (provider === 'apple') {
			return signInWithApple()
		} else if (provider === 'facebook') {
			return signInWithFacebook()
		} else {
			return signInWithEmailAndPassword(payload?.email!, payload?.password!)
		}
	}

	const login = (provider: AuthProvider, payload?: EmailPassPayload) => {
		return loginWithProviders(provider, payload).then(async (res) => {
			const { data } = await me()

			if (data.data.role === 'guest') {
				logout()
			} else {
				return res
			}
		})
	}

	const authError = useMemo(() => {
		const err =
			errorRegister?.code ||
			errorPassword?.code ||
			errorGoogle?.code ||
			errorApple?.code ||
			errorFacebook?.code ||
			errorCreds?.code

		return err ? `errors:firebase.${err}` : error?.message
	}, [
		errorRegister,
		errorPassword,
		errorGoogle,
		errorApple,
		errorFacebook,
		errorCreds,
		error,
	])

	return {
		fireAuthUser,
		isLoading: loading,
		authError,
		isAuth: !!fireAuthUser && !loading,
		loginWithCredentials,
		login,
		logout,
		createUserWithEmailAndPassword,
		sendPasswordResetEmail,
	}
}
