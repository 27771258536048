import type { Api } from '@cocoonspace/types/shared/api'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'

import { defineEndpoint } from '../utils/define-endpoint'

export interface DownloadInvoices {
	params?: NonNullable<
		Api.Operations['downloadInvoices']['parameters']
	>['query']
	response: Api.Operations['downloadInvoices']['responses']['200']
}

export const downloadInvoices = (
	axiosInstance: AxiosInstance,
	overideConfig?: AxiosRequestConfig,
) =>
	defineEndpoint<DownloadInvoices>(
		{
			method: 'get',
			url: '/invoices/download/recap',
			...overideConfig,
		},
		axiosInstance,
	)
