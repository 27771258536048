import { type FirebaseOptions, getApp, initializeApp } from 'firebase/app'
import { getAuth, getIdToken, onAuthStateChanged } from 'firebase/auth'

const firebaseConfig = {
	appId:
		process.env.EXPO_PUBLIC_FIREBASE_APP_ID ||
		process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
	apiKey:
		process.env.EXPO_PUBLIC_FIREBASE_API_KEY ||
		process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
	authDomain:
		process.env.EXPO_PUBLIC_FIREBASE_AUTH_DOMAIN ||
		process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
	projectId:
		process.env.EXPO_PUBLIC_FIREBASE_PROJECT_ID ||
		process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
	storageBucket:
		process.env.EXPO_PUBLIC_FIREBASE_STORAGE_BUCKET ||
		process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
	measurementId:
		process.env.EXPO_PUBLIC_FIREBASE_MEASUREMENT_ID ||
		process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
	messagingSenderId:
		process.env.EXPO_PUBLIC_FIREBASE_MESSAGING_SENDER_ID ||
		process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
}

export const getFirebaseApp = (config: FirebaseOptions = firebaseConfig) => {
	try {
		return getApp()
	} catch (err) {
		return initializeApp(config)
	}
}

getFirebaseApp(firebaseConfig)

export const getUserToken = async (): Promise<string | undefined> => {
	return new Promise((resolve) => {
		const unsub = onAuthStateChanged(getAuth(), async (user) => {
			if (user) {
				const token = await getIdToken(user)

				resolve(token)
			} else {
				resolve(undefined)
			}

			unsub()
		})
	})
}
