import type { GetPrices } from '@cocoonspace/sdk-js-legacy/endpoints/get-prices'
import type { GetSpace } from '@cocoonspace/sdk-js-legacy/endpoints/get-space'
import type { GetSpaceCalendar } from '@cocoonspace/sdk-js-legacy/endpoints/get-space-calendar'
import type { ListSpaceAvailabilities } from '@cocoonspace/sdk-js-legacy/endpoints/list-space-availabilities'
import type { ListSpaces } from '@cocoonspace/sdk-js-legacy/endpoints/list-spaces'
import { useMutation, useQuery } from '@tanstack/react-query'
import { stringify } from 'qs'
import { useAuth } from '../../domains/auth/hooks/use-auth.hook'
import { cleanQueryKeyFactories } from '../../utils/clean-query-key-factories'
import { useCocoonApi } from './use-cocoon-api.hook'

export namespace SpaceHooksCtx {
	export interface list {
		isAuth?: boolean
		filters?: ListSpaces['params']
	}

	export interface suggestions {
		isAuth?: boolean
		filters: Pick<
			NonNullable<ListSpaces['params']>,
			'zone' | 'latitude' | 'longitude' | 'capacity' | 'limit'
		>
	}

	export interface detail {
		spaceId: GetSpace['uriParams']['spaceId']
		isAuth?: boolean
		filters?: GetSpace['params']
	}

	export interface availabilities {
		filters?: ListSpaceAvailabilities['params']
		timeslots?: ListSpaceAvailabilities['body']
	}

	export interface calendar {
		spaceId: GetSpaceCalendar['uriParams']['spaceId']
		filters?: GetSpaceCalendar['params']
	}

	export interface prices {
		spaceId: GetPrices['uriParams']['spaceId']
		filters?: GetPrices['params']
	}
}

/**
 * Query keys
 */
export const SPACE_KEYS = cleanQueryKeyFactories({
	base: ['space'] as const,
	list: ({ filters, isAuth }: SpaceHooksCtx.list) =>
		[...SPACE_KEYS.base, 'list', filters, isAuth] as const,
	suggestions: ({ filters, isAuth }: SpaceHooksCtx.suggestions) =>
		[...SPACE_KEYS.base, 'suggestions', filters, isAuth] as const,
	detail: ({ spaceId, isAuth, filters }: SpaceHooksCtx.detail) =>
		[...SPACE_KEYS.base, 'detail', spaceId, isAuth, filters] as const,
	availabilities: ({ timeslots, filters }: SpaceHooksCtx.availabilities) =>
		[...SPACE_KEYS.base, 'availabilities', timeslots, filters] as const,
	calendar: ({ spaceId, filters }: SpaceHooksCtx.calendar) =>
		[...SPACE_KEYS.base, 'calendar', spaceId, filters] as const,
	prices: ({ spaceId, filters }: SpaceHooksCtx.prices) =>
		[...SPACE_KEYS.base, 'prices', spaceId, filters] as const,
} as const)

/**
 * List all spaces.
 * @param filters {@link SpaceHooksCtx.list['filters']}
 * @param opts
 */
export const useSpaceList = (
	filters?: SpaceHooksCtx.list['filters'],
	opts = {
		enabled: true,
		keepPreviousData: false,
	},
) => {
	const { isAuth, isLoading } = useAuth()
	const { getAll } = useCocoonApi('spaces')

	return useQuery({
		queryKey: SPACE_KEYS.list({ isAuth, filters }),
		queryFn: () =>
			getAll({
				params: filters,
				paramsSerializer: (params) =>
					stringify(params, { arrayFormat: 'repeat', allowDots: true }),
			}).then((res) => res.data),
		enabled: !isLoading && opts.enabled,
		keepPreviousData: opts.keepPreviousData,
	})
}

/**
 * Get a specific space's details
 * @param spaceId {@link SpaceHooksCtx.detail['spaceId']}
 * @param filters {@link SpaceHooksCtx.detail['filters']}
 */
export const useSpaceDetails = (
	spaceId: SpaceHooksCtx.detail['spaceId'],
	filters?: SpaceHooksCtx.detail['filters'],
	opts = { keepPreviousData: false },
) => {
	const { isAuth, isLoading } = useAuth()
	const { getOne } = useCocoonApi('spaces')

	return useQuery({
		queryKey: SPACE_KEYS.detail({ spaceId, isAuth, filters }),
		queryFn: () =>
			getOne({ uriParams: { spaceId }, params: filters }).then(
				(res) => res.data.data,
			),
		enabled: !isLoading && !!spaceId,
		keepPreviousData: opts.keepPreviousData,
	})
}

export const useSpaceFavoriteUpdate = () => {
	const { addToFavorites, removeFromFavorites } = useCocoonApi('users')

	return useMutation({
		mutationFn: ({ id, favorite }: { id: string; favorite?: boolean }) => {
			return favorite
				? removeFromFavorites({ uriParams: { spaceId: id } })
				: addToFavorites({ uriParams: { spaceId: id } })
		},
		onSuccess: () => {},
	})
}

export const useCalendar = (
	spaceId: SpaceHooksCtx.calendar['spaceId'],
	filters?: SpaceHooksCtx.calendar['filters'],
) => {
	const { getCalendar } = useCocoonApi('spaces')

	const fetcher = () =>
		getCalendar({
			uriParams: { spaceId },
			params: filters,
		}).then((res) => res.data.data)

	const query = useQuery({
		queryKey: SPACE_KEYS.calendar({ spaceId, filters }),
		queryFn: fetcher,
		enabled: Boolean(spaceId && filters?.day),
	})

	return query
}

export const useSpacePrices = (
	spaceId: SpaceHooksCtx.prices['spaceId'],
	filters?: SpaceHooksCtx.prices['filters'],
) => {
	const { getPrices } = useCocoonApi('spaces')

	const fetcher = () =>
		getPrices({
			uriParams: { spaceId },
			params: filters,
		}).then((res) => res.data.data)

	return useQuery({
		queryKey: SPACE_KEYS.prices({ spaceId, filters }),
		queryFn: fetcher,
		enabled: !!(spaceId && filters?.to),
	})
}
