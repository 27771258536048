import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { LegalFooter } from '~/components/templates/site-footer/legal-footer'
import { cn } from '~/lib/utils'

import { Separator } from '~/components/ui/separator'
import { BaselineAndRating } from './baseline-and-rating'

const footerLinks = [
	{
		title: 'cwFooter:cocoons.title',
		links: [
			{
				label: 'cwFooter:offers.meetingRoomsParis',
				href: '/salle-de-reunion/paris',
			},
			{
				label: 'cwFooter:offers.officesParis',
				href: '/bureau-a-l-heure/paris',
			},
			{
				label: 'cwFooter:offers.meetingRoomsLyon',
				href: '/salle-de-reunion/lyon',
			},
			{
				label: 'cwFooter:offers.officesLyon',
				href: '/bureau-a-l-heure/lyon',
			},
		],
	},
	{
		title: 'cwFooter:findUs.title',
		links: [
			{ label: 'Facebook', href: 'https://www.facebook.com/mycocoonspace' },
			{
				label: 'Instagram',
				href: 'https://www.instagram.com/cocoonspace/?hl=fr',
			},
			{ label: 'X (Twitter)', href: 'https://x.com/cocoon_space' },
			{
				label: 'LinkedIn',
				href: 'https://fr.linkedin.com/company/cocoon-space',
			},
		],
	},
	{
		title: 'cwFooter:help.title',
		links: [
			{ label: 'cwFooter:help.who', href: '/a-propos' },
			{ label: 'cwFooter:help.faq', href: 'https://support.cocoon-space.com/' },
			{
				label: 'cwFooter:help.contact',
				href: 'mailto:contact@cocoon-space.com',
			},
		],
	},
] as const

const LegalAndSocial = () => (
	<div className='flex flex-col justify-between gap-4 md:flex-row'>
		<div className='text-center text-slate-500 text-xs'>
			{`Copyright © ${new Date().getFullYear()} Cocoon Space`}
		</div>

		<div className='flex flex-col items-center justify-center gap-4 md:flex-row'>
			<LegalFooter />
		</div>
	</div>
)

export const SiteFooter = () => {
	const { t } = useTranslation()
	const router = useRouter()

	return (
		<footer
			className={cn('bg-white print:hidden', {
				'max-md:hidden': router.route === '/dashboard',
			})}
		>
			<div className='container flex flex-col gap-6 py-8'>
				<div className='flex flex-col gap-4 md:flex-row'>
					<BaselineAndRating />

					<div className='flex flex-col gap-4 md:ml-auto md:flex-row md:gap-[100px]'>
						{footerLinks.map((section, i) => (
							<ul
								key={i}
								className='flex flex-col'
							>
								<li className='mb-1 font-bold'>{t(section.title)}</li>

								{section.links.map((link) => (
									<li key={link.label}>
										<Link
											href={link.href}
											target='_blank'
											className='text-sm hover:text-primary'
										>
											{t(link.label)}
										</Link>
									</li>
								))}
							</ul>
						))}
					</div>
				</div>

				<Separator />
				<LegalAndSocial />
			</div>
		</footer>
	)
}
