import { useRouter } from 'next/router'

import type { ComponentProps } from 'react'
import { ExpirationDialog } from '../molecules/expiration-dialog'

type Mode = ComponentProps<typeof ExpirationDialog>['mode']

const routesToMode: Record<string, Mode> = {
	'/booking/[bookingId]/extras': 'booking',
	'/booking/[bookingId]/payment': 'booking',
	'/event/extras/[bookingId]': 'extras',
}

export const BookingExpirationDialogManager = () => {
	const router = useRouter()

	const mode: Mode | undefined = routesToMode[router.route]

	if (!!mode) return <ExpirationDialog mode={mode} />

	return null
}
