import * as React from 'react'
import type { SVGProps } from 'react'

export const SvgHourglass = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 384 512'
		width='1em'
		height='1em'
		{...props}
	>
		<path
			fill='currentColor'
			d='M16 0C7.2 0 0 7.2 0 16s7.2 16 16 16h16v44.7c0 31.6 10.4 62.4 29.6 87.5l70.3 91.8-70.3 91.9C42.4 373 32 403.7 32 435.3V480H16c-8.8 0-16 7.2-16 16s7.2 16 16 16h352c8.8 0 16-7.2 16-16s-7.2-16-16-16h-16v-44.7c0-31.6-10.4-62.4-29.6-87.5L252.1 256l70.2-91.9C341.6 139 352 108.3 352 76.7V32h16c8.8 0 16-7.2 16-16s-7.2-16-16-16H16zm304 32v44.7c0 24.6-8.1 48.5-23 68l-77.7 101.6c-4.4 5.7-4.4 13.7 0 19.4L297 367.3c14.9 19.5 23 43.4 23 68V480H64v-44.7c0-24.6 8.1-48.5 23-68l77.7-101.6c4.4-5.7 4.4-13.7 0-19.4L87 144.7c-14.9-19.5-23-43.4-23-68V32h256z'
		/>
	</svg>
)
