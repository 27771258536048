import { track } from '@vercel/analytics'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { createZustandSelectors } from '~/lib/create-zustand-selectors'

export interface CookieConsent {
	front: boolean
	// mixpanel: boolean
}

type View = 'intro' | 'selection'

interface CookieBannerStore {
	isOpen: boolean
	view: View
	consent: CookieConsent
	setIsOpen: (isOpen: boolean) => void
	setView: (view: View) => void
	acceptAll: () => void
	denyAll: () => void
	setConsent: (service: keyof CookieConsent, consent: boolean) => void
}

const useCookieBannerStoreBase = create<CookieBannerStore>()(
	persist(
		(set, get) => ({
			isOpen: true,
			view: 'intro',
			consent: {
				front: false,
				// mixpanel: false,
			},
			setIsOpen: (isOpen) => {
				set({
					isOpen,
					view: 'intro',
				})

				const chekedConsents = Object.entries(get().consent)
					.filter(([_, consent]) => consent)
					.map(([service]) => service)

				const allChecked =
					chekedConsents.length === Object.keys(get().consent).length

				if (!isOpen) {
					if (allChecked) {
						track('cookie-banner-consent', { consent: 'all' })
					} else if (chekedConsents.length === 0) {
						track('cookie-banner-consent', { consent: 'none' })
					} else {
						track('cookie-banner-consent', {
							consent: chekedConsents.join(','),
						})
					}
				}

				if (typeof window !== 'undefined' && !isOpen && !allChecked) {
					window.location.reload()
				}
			},
			setView: (view) => set({ view }),
			acceptAll: () => {
				set({
					isOpen: false,
					view: 'intro',
					consent: {
						front: true,
						// mixpanel: true,
					},
				})

				track('cookie-banner-consent', { consent: 'all' })
			},
			denyAll: () => {
				set({
					isOpen: false,
					view: 'intro',
					consent: {
						front: false,
						// mixpanel: false,
					},
				})

				if (typeof window !== 'undefined') {
					window.location.reload()
				}

				track('cookie-banner-consent', { consent: 'none' })
			},
			setConsent: (service, consent) =>
				set((state) => ({
					consent: {
						...state.consent,
						[service]: consent,
					},
				})),
		}),
		{ name: 'cookie-banner' },
	),
)

export const useCookieBannerStore = createZustandSelectors(
	useCookieBannerStoreBase,
)
