const appEnv =
	process.env.EXPO_PUBLIC_APP_ENV || process.env.NEXT_PUBLIC_APP_ENV

export const APP_CONFIG = {
	appleAppId: '1217025853',
	appEnv,
	appUrl:
		appEnv === 'prod'
			? 'https://www.cocoon-space.com'
			: 'https://preprod.cocoon-space.dev',
}
