import { getFirebaseApp, getUserToken } from '@cocoonspace/shared/libs/firebase'
import { AxiosRequestConfig } from 'axios'

getFirebaseApp({
	appId:
		process.env.EXPO_PUBLIC_FIREBASE_APP_ID ||
		process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
	apiKey:
		process.env.EXPO_PUBLIC_FIREBASE_API_KEY ||
		process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
	authDomain:
		process.env.EXPO_PUBLIC_FIREBASE_AUTH_DOMAIN ||
		process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
	projectId:
		process.env.EXPO_PUBLIC_FIREBASE_PROJECT_ID ||
		process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
	storageBucket:
		process.env.EXPO_PUBLIC_FIREBASE_STORAGE_BUCKET ||
		process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
	measurementId:
		process.env.EXPO_PUBLIC_FIREBASE_MEASUREMENT_ID ||
		process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
	messagingSenderId:
		process.env.EXPO_PUBLIC_FIREBASE_MESSAGING_SENDER_ID ||
		process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
})

export const accessTokenInterceptor = async (config: any) => {
	const token = await getUserToken()

	if (token) {
		config.headers = config.headers || {}
		config.headers['Authorization'] = `Bearer ${token}`
	}

	return config
}
