const { fontFamily } = require('tailwindcss/defaultTheme')

/** @type {import('tailwindcss').Config} */
module.exports = {
	theme: {
		extend: {
			fontFamily: {
				primary: ['var(--nunito-font)', ...fontFamily.sans],
				serif: ['var(--nunito-font)', ...fontFamily.serif],
			},
			borderColor: {
				DEFAULT: '#ADB5BD',
			},
			colors: {
				background: {
					default: '#f1f5f9',
				},
				text: {
					primary: '#000',
				},
				primary: {
					xxlight: '#FEF0EE',
					xlight: '#ff5c28',
					light: '#ff5c28',
					semilight: '#FF7E62',
					main: '#ff4338',
					dark: '#eb3e34',
				},
				success: {
					main: '#D1FAE5',
					dark: '#09572f',
				},
				error: {
					light: '#ff5c28',
					main: '#ff4338',
					dark: '#eb3e34',
				},
				gray: {
					300: '#F9F9FB',
					400: '#F3F3F3',
					500: '#ECECEC',
					600: '#DEE2E6',
					700: '#ADB5BD',
					800: '#797979',
					// 800: '#979797',
				},
			},
			fontSize: {
				xxs: '0.625rem', // 10px
				xs: '0.75rem', // 12px
				sm: '0.875rem', // 14px
				base: '1rem', // 16px
				lg: '1.125rem', // 18px
				xl: '1.25rem', // 20px
				'2xl': '1.375rem', // 22px
				'3xl': '1.5rem', // 24px
				'4xl': '1.625rem', // 26px
				'6xl': '2.625rem', // 42px
			},
			borderRadius: {
				DEFAULT: '8px',
			},
			container: {
				center: true,
				padding: {
					DEFAULT: '24px',
				},
				screens: {
					xl: '1200px',
				},
			},
			spacing: {
				sm: '0.5rem', // 2
				md: '1rem', // 4
				lg: '1.5rem', // 6
				xl: '2rem', // 8
			},
			boxShadow: {
				DEFAULT: '0 2px 27px 0 rgba(0, 0, 0, 0.05)',
				// DEFAULT: '0 35px 60px -15px rgba(0, 0, 0, 0.3)',
			},
		},
	},
}
