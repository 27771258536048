import type { Api } from '@cocoonspace/types/shared/api'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'

import { defineEndpoint } from '../utils/define-endpoint'

export interface GetMe {
	params?: NonNullable<Api.Operations['getMe']['parameters']>['query']
	response: Api.Operations['getMe']['responses']['200']['content']['application/json']
}

export const getMe = (
	axiosInstance: AxiosInstance,
	overideConfig?: AxiosRequestConfig,
) =>
	defineEndpoint<GetMe>(
		{
			method: 'get',
			url: '/me',
			...overideConfig,
		},
		axiosInstance,
	)
