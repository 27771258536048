import type { Emitter, EventType, Handler } from 'mitt'
import { type DependencyList, useEffect } from 'react'

export const useEmitter = <
	T extends Record<EventType, unknown>,
	Key extends keyof T,
>(
	emitter: Emitter<T>,
	events: { [K in Key]?: Handler<T[K]> },
	deps: DependencyList = [],
) => {
	useEffect(
		() => {
			for (const k in events) {
				const handler = events[k as Key]!

				emitter.on(k, handler)
			}

			return () => {
				for (const k in events) {
					const handler = events[k as Key]!

					emitter.off(k, handler)
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		deps,
	)
}
