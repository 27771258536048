import type dayjs from 'dayjs'
import type { Dayjs, PluginFunc } from 'dayjs'
import type { DurationUnitType } from 'dayjs/plugin/duration'

export type StringTime = `${string}:${string}` | string

type DaysFormats =
	| 'dateTech'
	| 'dateMonthDay'
	| 'date'
	| 'shortMonthYear'
	| 'fullMonthYear'
	| 'dateShortSingleDigit'
	| 'dateShort'
	| 'fullDayFullMonth'
	| 'dateMedium'
	| 'dateLong'
	| 'dateFull'
	| 'dateTime'
	| 'dateTimeFull'
	| 'shortDayLongMonthYear'
	| 'hours'

type LangFormat = Record<DaysFormats, string>

const fr: LangFormat = {
	dateTech: 'YYYY-MM-DD',
	dateMonthDay: 'DD/MM',
	date: 'DD/MM/YYYY',
	shortMonthYear: 'MMM YYYY',
	fullMonthYear: 'MMMM YYYY',
	dateShortSingleDigit: 'D MMMM',
	dateShort: 'DD MMMM',
	fullDayFullMonth: 'Do MMMM',
	dateMedium: 'Do MMMM YYYY',
	dateLong: 'dddd DD MMMM',
	dateFull: 'dddd DD MMMM YYYY',
	dateTime: 'DD/MM/YYYY HH[h]mm',
	dateTimeFull: 'Do MMMM [à] H[h]mm',
	shortDayLongMonthYear: 'ddd Do MMM YYYY',
	hours: 'H[h]mm',
}

const en: LangFormat = {
	dateTech: 'YYYY-MM-DD',
	dateMonthDay: 'MM/DD',
	date: 'YYYY/MM/DD',
	shortMonthYear: 'MMM YYYY',
	fullMonthYear: 'MMMM YYYY',
	dateShortSingleDigit: 'D MMMM',
	dateShort: 'DD MMMM',
	fullDayFullMonth: 'Do MMMM',
	dateMedium: 'Do MMMM YYYY',
	dateLong: 'dddd DD MMMM',
	dateFull: 'dddd DD MMMM YYYY',
	dateTime: 'DD/MM/YYYY HH:mm',
	dateTimeFull: 'Do MMMM [at] H:mm',
	shortDayLongMonthYear: 'ddd Do MMM YYYY',
	hours: 'H:mm',
}

const formatsRefs: Record<string, LangFormat> = {
	fr,
	en,
}

export const createDayjsUtils = (
	option: any,
	dayjsClass: typeof dayjs.Dayjs,
	dayjsFactory: typeof dayjs,
) => {
	/**
	 * @example
	 * `930` -> dayjs.Dayjs
	 * `09:30` -> dayjs.Dayjs
	 * */
	const parseTime = (time: StringTime | number) => {
		return typeof time === 'number'
			? dayjsFactory(time.toString().padStart(4, '0'), 'HHmm')
			: dayjsFactory(time, 'HH:mm')
	}

	/** @example `dayjs.Dayjs` ->  `HH:mm` */
	const formatToStringTime = (time: Dayjs) => time.format('HH:mm')

	/** @example `dayjs.Dayjs` ->  `930` */
	const formatToNumberTime = (time: Dayjs) => Number(time.format('Hmm'))

	/** @example `930` ->  `09:30` */
	const convertToStringTime = (numberTime: number) => {
		const date = parseTime(numberTime)

		return formatToStringTime(date)
	}

	/** @example `09:30` -> `930` */
	const convertToNumberTime = (stringTime: StringTime) => {
		const date = parseTime(stringTime)

		return formatToNumberTime(date)
	}

	/**
	 * @example `09:30` + 30 -> `10:00`
	 * @example `930` + 30 -> `1000`
	 * */
	const addDurationToTime = <T extends number | StringTime>(
		time: T,
		amount: number,
		unit?: Extract<DurationUnitType, 'minute' | 'hour'>,
	): T extends number ? number : string => {
		const date = parseTime(time).add(amount, unit || 'minute')

		return typeof time === 'number'
			? (formatToNumberTime(date) as T extends number ? number : string)
			: (formatToStringTime(date) as T extends number ? number : string)
	}

	/**
	 * @example `01:30` -> `120`
	 * @example `130` -> `120`
	 * */
	const getTimeAsMinutes = (time?: StringTime | number) => {
		if (!time) return 0

		const date = parseTime(time)

		return dayjsFactory
			.duration({ hours: date.hour(), minutes: date.minute() })
			.asMinutes()
	}

	return {
		parseTime,
		formatToStringTime,
		formatToNumberTime,
		convertToStringTime,
		convertToNumberTime,
		addDurationToTime,
		getTimeAsMinutes,
	}
}

declare module 'dayjs' {
	interface Dayjs {
		getTimeAsMinutes(): number
		customFormat: (templateKey: DaysFormats) => string
	}

	export const utils: ReturnType<typeof createDayjsUtils>
}

export const dayjsUtils: PluginFunc = (option, dayjsClass, dayjsFactory) => {
	const utils = createDayjsUtils(option, dayjsClass, dayjsFactory)

	dayjsClass.prototype.getTimeAsMinutes = function () {
		const time = dayjsFactory.utils.formatToStringTime(this)

		return utils.getTimeAsMinutes(time as StringTime)
	}

	dayjsClass.prototype.customFormat = function (templateKey: DaysFormats) {
		const locale = this.locale()
		const template = formatsRefs[locale][templateKey]

		return dayjsClass.prototype.format.bind(this)(template || templateKey)
	}

	Object.assign(dayjsFactory, { utils })
}
