import { districts } from './districts'

export const searchConfig = {
	timeSlots: [
		'08:00',
		'08:30',
		'09:00',
		'09:30',
		'10:00',
		'10:30',
		'11:00',
		'11:30',
		'12:00',
		'12:30',
		'13:00',
		'13:30',
		'14:00',
		'14:30',
		'15:00',
		'15:30',
		'16:00',
		'16:30',
		'17:00',
		'17:30',
		'18:00',
		'18:30',
		'19:00',
		'19:30',
		'20:00',
		'20:30',
		'21:00',
		'21:30',
		'22:00',
		'22:30',
		'23:00',
		'23:30',
	],
	timeSlot: {
		min: '08:00',
		max: '23:00',
	},
	zones: {
		paris: {
			latitude: 48.8570885,
			longitude: 2.319991,
			center: { lat: 48.8589, lng: 2.3204 },
			zoom: { min: 2, max: 17, search: 12, space: 17 },
			bounds: {
				swLat: 48.793276,
				swLng: 2.206351,
				neLat: 48.920901,
				neLng: 2.433631,
			},
		},
		lyon: {
			latitude: 45.7603831,
			longitude: 4.849664,
			center: { lat: 45.7603831, lng: 4.849664 },
			zoom: { min: 2, max: 14, search: 11, space: 14 },
			bounds: {
				swLat: 45.74071912703303,
				swLng: 4.813594962061694,
				neLat: 45.79472440134,
				neLng: 4.850987637938311,
			},
		},
	} as const,
	districts,
	routesWithoutSearch: [
		'/booking',
		'/booking/[bookingId]/extras',
		'/booking/[bookingId]/payment',
		'/event/[eventId]/extras',
		'/event/extras/[bookingId]',
		'/[zone]',
		'/desks/[zone]',
		'/salle-de-reunion/[zone]',
		'/salle-de-reunion/[zone]/[district]',
		'/bureau-a-l-heure/[zone]',
		'/onboarding',
		'/onboarding/email',
		'/onboarding/personal-infos',
		'/onboarding/password',
		'/onboarding/email-validation',
	],
	defaultZone: 'paris' as const,
}
