import type { AxiosInstance } from 'axios'

import { addAccountPaymentMethod } from './../endpoints/add-account-payment-method'
import { deleteAccountPaymentMethod } from './../endpoints/delete-account-payment-method'
import { getAccountPaymentMethods } from './../endpoints/get-account-payment-methods'
import { setDefaultAccountPaymentMethod } from './../endpoints/set-default-account-payment-method'

export const paymentMethodsModule = (axiosInstance: AxiosInstance) => {
	return {
		getAll: getAccountPaymentMethods(axiosInstance),
		createOne: addAccountPaymentMethod(axiosInstance),
		deleteOne: deleteAccountPaymentMethod(axiosInstance),
		setDefault: setDefaultAccountPaymentMethod(axiosInstance),
	}
}
