import * as React from 'react'
import type { SVGProps } from 'react'

export const SvgHourglassClock = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 576 512'
		width='1em'
		height='1em'
		{...props}
	>
		<path
			fill='currentColor'
			d='M16 512c-8.8 0-16-7.2-16-16s7.2-16 16-16h16v-44.7c0-31.6 10.4-62.4 29.6-87.5l70.3-91.8-70.3-91.9C42.4 139 32 108.3 32 76.7V32H16C7.2 32 0 24.8 0 16S7.2 0 16 0h352c8.8 0 16 7.2 16 16s-7.2 16-16 16h-16v44.7c0 31.6-10.4 62.4-29.6 87.5L252.1 256l23.6 30.9c-5.7 11-10.3 22.7-13.6 34.9l-42.8-56c-4.4-5.7-4.4-13.7 0-19.4L297 144.7c14.9-19.5 23-43.4 23-68V32H64v44.7c0 24.6 8.1 48.5 23 68l77.7 101.6c4.4 5.7 4.4 13.7 0 19.4L87 367.3c-14.9 19.5-23 43.4-23 68V480h232.2c10 12.1 21.7 22.9 34.6 32H16zm176-96h70.6c3.2 11.2 7.4 21.9 12.6 32H112c-6 0-11.5-3.4-14.2-8.7s-2.3-11.7 1.2-16.6l80-112c5.1-7.2 15.1-8.9 22.3-3.7s8.9 15.1 3.7 22.3L143.1 416H192zm0-208c-4.9 0-9.5-2.2-12.5-6l-64-80c-3.8-4.8-4.6-11.4-1.9-16.9S121.9 96 128 96h128c6.2 0 11.8 3.5 14.4 9.1s1.9 12.1-1.9 16.9l-64 80c-3 3.8-7.6 6-12.5 6zm-30.7-80 30.7 38.4 30.7-38.4h-61.4zM432 480a112 112 0 1 0 0-224 112 112 0 1 0 0 224zm0-256a144 144 0 1 1 0 288 144 144 0 1 1 0-288zm0 64c8.8 0 16 7.2 16 16v48h32c8.8 0 16 7.2 16 16s-7.2 16-16 16h-48c-8.8 0-16-7.2-16-16v-64c0-8.8 7.2-16 16-16z'
		/>
	</svg>
)
