import type { Api } from '@cocoonspace/types/shared/api'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'

import { defineEndpoint } from '../utils/define-endpoint'

export interface GetBooking {
	uriParams: Api.Operations['getBooking']['parameters']['path']
	response: Api.Operations['getBooking']['responses']['200']['content']['application/json']
}

export const getBooking = (
	axiosInstance: AxiosInstance,
	overideConfig?: AxiosRequestConfig,
) =>
	defineEndpoint<GetBooking>(
		{
			method: 'get',
			url: '/bookings/{bookingId}',
			...overideConfig,
		},
		axiosInstance,
	)
