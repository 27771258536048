import axios, { type AxiosInstance } from 'axios'

import type { ReqConfig } from '../lib'
import { pathTemplating } from './path-templating'

export type UriParams<T> = T extends { uriParams: infer B } ? B : never
export type QueryParams<T> = T extends { params?: infer B } ? B : never
export type Body<T> = T extends { body: infer B } ? B : never
export type Response<T> = T extends { response: infer B } ? B : never

export interface EndpointConfig<T> {
	defaultOptions?: ReqConfig<UriParams<T>, QueryParams<T>, Body<T>>
	path: string
}

export interface DefaultEndpoint {
	uriParams?: Record<string, unknown>
	params?: Record<string, unknown>
	body?: unknown
	response: unknown
}

export const defineEndpoint = <T extends DefaultEndpoint>(
	defaultOptions: ReqConfig<UriParams<T>, QueryParams<T>, Body<T>> = {},
	axiosInstance: AxiosInstance = axios.create(),
) => {
	return (reqConfig: ReqConfig<UriParams<T>, QueryParams<T>, Body<T>> = {}) => {
		const { uriParams, ...config } = reqConfig

		const opts = {
			...defaultOptions,
			...config,
			url: pathTemplating(
				defaultOptions.url as string,
				uriParams as Record<string, unknown>,
			),
		}

		return axiosInstance.request<Response<T>>(opts)
	}
}
