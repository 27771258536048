import type { ControllerProps, FieldPath, FieldValues } from 'react-hook-form'

import {
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from '~/components/ui/form'

interface FormControlledInputProps {
	label?: string
	required?: boolean
}

export const FormControlledInput = <
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
	label,
	required,
	render,
	...props
}: ControllerProps<TFieldValues, TName> & FormControlledInputProps) => (
	<FormField
		{...props}
		render={(renderProps) => (
			<FormItem>
				{!!label && (
					<FormLabel>
						{label}

						{!!required && <sup>*</sup>}
					</FormLabel>
				)}

				<FormControl>{render(renderProps)}</FormControl>

				<FormMessage />
			</FormItem>
		)}
	/>
)
