import type { AxiosInstance } from 'axios'

import { getExtra } from './../endpoints/get-extra'
import { listExtras } from './../endpoints/list-extras'

export const extraModule = (axiosInstance: AxiosInstance) => {
	return {
		getAll: listExtras(axiosInstance),
		getOne: getExtra(axiosInstance),
	}
}
