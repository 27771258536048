import type { Api } from '@cocoonspace/types/shared/api'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'

import { defineEndpoint } from '../utils/define-endpoint'

export interface UpdateAccount {
	body: NonNullable<
		Api.Operations['updateAccount']['requestBody']
	>['content']['application/json']
	response: Api.Operations['updateAccount']['responses']['200']['content']['application/json']
}

export const updateAccount = (
	axiosInstance: AxiosInstance,
	overideConfig?: AxiosRequestConfig,
) =>
	defineEndpoint<UpdateAccount>(
		{
			method: 'patch',
			url: '/account',
			...overideConfig,
		},
		axiosInstance,
	)
