import type { Api } from '@cocoonspace/types/shared/api'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'

import { defineEndpoint } from '../utils/define-endpoint'

export interface AddAccountPaymentMethod {
	body: Api.Schemas['AccountPaymentMethodAddRequest']
	response: Api.Operations['addAccountPaymentMethod']['responses']['200']['content']['application/json']
}

export const addAccountPaymentMethod = (
	axiosInstance: AxiosInstance,
	overideConfig?: AxiosRequestConfig,
) =>
	defineEndpoint<AddAccountPaymentMethod>(
		{
			method: 'post',
			url: '/account/payment_methods',
			...overideConfig,
		},
		axiosInstance,
	)
