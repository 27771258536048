export const districts = {
	paris: [
		{
			zip: '75001',
			uriParam: '1er-arrondissement-75001',
			latitude: 48.8640493,
			longitude: 2.3310526,
			spaceSuggestionBody:
				"Vous recherchez d'autres bureaux ou salles de réunion sur Paris ?<br/>Voici une sélection de Cocoons dans le 1er arrondissement et alentour.",
		},
		{
			zip: '75002',
			uriParam: '2eme-arrondissement-75002',
			latitude: 48.8675641,
			longitude: 2.34399,
			spaceSuggestionBody:
				"Vous recherchez d'autres bureaux ou salles de réunion sur Paris ?<br/>Voici une sélection de Cocoons dans le 2e arrondissement et alentour.",
		},
		{
			zip: '75003',
			uriParam: '3eme-arrondissement-75003',
			latitude: 48.8634799,
			longitude: 2.3591145,
			spaceSuggestionBody:
				"Vous recherchez d'autres bureaux ou salles de réunion sur Paris ?<br/>Voici une sélection de Cocoons dans le 3e arrondissement et alentour.",
		},
		{
			zip: '75004',
			uriParam: '4eme-arrondissement-75004',
			latitude: 48.8534275,
			longitude: 2.3582788,
			spaceSuggestionBody:
				"Vous recherchez d'autres bureaux ou salles de réunion sur Paris ?<br/>Voici une sélection de Cocoons dans le 4e arrondissement et alentour.",
		},
		{
			zip: '75005',
			uriParam: '5eme-arrondissement-75005',
			latitude: 48.8434912,
			longitude: 2.3518339,
			spaceSuggestionBody:
				"Vous recherchez d'autres bureaux ou salles de réunion sur Paris ?<br/>Voici une sélection de Cocoons dans le 5e arrondissement et alentour.",
		},
		{
			zip: '75006',
			uriParam: '6eme-arrondissement-75006',
			latitude: 48.8488576,
			longitude: 2.3354223,
			spaceSuggestionBody:
				"Vous recherchez d'autres bureaux ou salles de réunion sur Paris ?<br/>Voici une sélection de Cocoons dans le 6e arrondissement et alentour.",
		},
		{
			zip: '75007',
			uriParam: '7eme-arrondissement-75007',
			latitude: 48.85433450000001,
			longitude: 2.3134029,
			spaceSuggestionBody:
				"Vous recherchez d'autres bureaux ou salles de réunion sur Paris ?<br/>Voici une sélection de Cocoons dans le 7e arrondissement et alentour.",
		},
		{
			zip: '75008',
			uriParam: '8eme-arrondissement-75008',
			latitude: 48.8718722,
			longitude: 2.3176432,
			spaceSuggestionBody:
				"Vous recherchez d'autres bureaux ou salles de réunion sur Paris ?<br/>Voici une sélection de Cocoons dans le 8e arrondissement et alentour.",
		},
		{
			zip: '75009',
			uriParam: '9eme-arrondissement-75009',
			latitude: 48.8790183,
			longitude: 2.3379063,
			spaceSuggestionBody:
				"Vous recherchez d'autres bureaux ou salles de réunion sur Paris ?<br/>Voici une sélection de Cocoons dans le 9e arrondissement et alentour.",
		},
		{
			zip: '75010',
			uriParam: '10eme-arrondissement-75010',
			latitude: 48.8785618,
			longitude: 2.3603689,
			spaceSuggestionBody:
				"Vous recherchez d'autres bureaux ou salles de réunion sur Paris ?<br/>Voici une sélection de Cocoons dans le 10e arrondissement et alentour.",
		},
		{
			zip: '75011',
			uriParam: '11eme-arrondissement-75011',
			latitude: 48.85799300000001,
			longitude: 2.381153,
			spaceSuggestionBody:
				"Vous recherchez d'autres bureaux ou salles de réunion sur Paris ?<br/>Voici une sélection de Cocoons dans le 11e arrondissement et alentour.",
		},
		{
			zip: '75012',
			uriParam: '12eme-arrondissement-75012',
			latitude: 48.85799300000001,
			longitude: 2.381153,
			spaceSuggestionBody:
				"Vous recherchez d'autres bureaux ou salles de réunion sur Paris ?<br/>Voici une sélection de Cocoons dans le 12e arrondissement et alentour.",
		},
		{
			zip: '75013',
			uriParam: '13eme-arrondissement-75013',
			latitude: 48.830759,
			longitude: 2.359204,
			spaceSuggestionBody:
				"Vous recherchez d'autres bureaux ou salles de réunion sur Paris ?<br/>Voici une sélection de Cocoons dans le 13e arrondissement et alentour.",
		},
		{
			zip: '75014',
			uriParam: '14eme-arrondissement-75014',
			latitude: 48.8314408,
			longitude: 2.3255684,
			spaceSuggestionBody:
				"Vous recherchez d'autres bureaux ou salles de réunion sur Paris ?<br/>Voici une sélection de Cocoons dans le 14e arrondissement et alentour.",
		},
		{
			zip: '75015',
			uriParam: '15eme-arrondissement-75015',
			latitude: 48.8421616,
			longitude: 2.2927665,
			spaceSuggestionBody:
				"Vous recherchez d'autres bureaux ou salles de réunion sur Paris ?<br/>Voici une sélection de Cocoons dans le 15e arrondissement et alentour.",
		},
		{
			zip: '75016',
			uriParam: '16eme-arrondissement-75016',
			latitude: 48.8530933,
			longitude: 2.2487626,
			spaceSuggestionBody:
				"Vous recherchez d'autres bureaux ou salles de réunion sur Paris ?<br/>Voici une sélection de Cocoons dans le 16e arrondissement et alentour.",
		},
		{
			zip: '75116',
			uriParam: '16eme-arrondissement-75116',
			latitude: 48.8530933,
			longitude: 2.2487626,
			spaceSuggestionBody:
				"Vous recherchez d'autres bureaux ou salles de réunion sur Paris ?<br/>Voici une sélection de Cocoons dans le 16e arrondissement et alentour.",
		},
		{
			zip: '75017',
			uriParam: '17eme-arrondissement-75017',
			latitude: 48.891986,
			longitude: 2.319287,
			spaceSuggestionBody:
				"Vous recherchez d'autres bureaux ou salles de réunion sur Paris ?<br/>Voici une sélection de Cocoons dans le 17e arrondissement et alentour.",
		},
		{
			zip: '75018',
			uriParam: '18eme-arrondissement-75018',
			latitude: 48.891305,
			longitude: 2.3529867,
			spaceSuggestionBody:
				"Vous recherchez d'autres bureaux ou salles de réunion sur Paris ?<br/>Voici une sélection de Cocoons dans le 18e arrondissement et alentour.",
		},
		{
			zip: '75019',
			uriParam: '19eme-arrondissement-75019',
			latitude: 48.89061359999999,
			longitude: 2.3867083,
			spaceSuggestionBody:
				"Vous recherchez d'autres bureaux ou salles de réunion sur Paris ?<br/>Voici une sélection de Cocoons dans le 19e arrondissement et alentour.",
		},
		{
			zip: '75020',
			uriParam: '20eme-arrondissement-75020',
			latitude: 48.8599825,
			longitude: 2.4066412,
			spaceSuggestionBody:
				"Vous recherchez d'autres bureaux ou salles de réunion sur Paris ?<br/>Voici une sélection de Cocoons dans le 20e arrondissement et alentour.",
		},
		{
			zip: '92800',
			uriParam: 'la-defense-92800',
			latitude: 48.8969888,
			longitude: 2.2272637,
			spaceSuggestionBody:
				"Vous recherchez d'autres bureaux ou salles de réunion sur Paris La Défense ?<br/>Voici une sélection de Cocoons dans le quartier de la Défense et alentour.",
		},
		{
			zip: '92911',
			uriParam: 'la-defense-92911',
			latitude: 48.8897359,
			longitude: 2.2418428,
			spaceSuggestionBody:
				"Vous recherchez d'autres bureaux ou salles de réunion sur Paris La Défense ?<br/>Voici une sélection de Cocoons dans le quartier de la Défense et alentour.",
		},
	],
	lyon: [
		{
			zip: '69001',
			uriParam: '1er-arrondissement-69001',
			latitude: 45.7712918,
			longitude: 4.8280831,
			spaceSuggestionBody:
				"Vous recherchez d'autres bureaux ou salles de réunion sur Lyon ?<br/>Voici une sélection de Cocoons dans le 1er arrondissement et alentour.",
		},
		{
			zip: '69002',
			uriParam: '2eme-arrondissement-69002',
			latitude: 45.7477113,
			longitude: 4.8240999,
			spaceSuggestionBody:
				"Vous recherchez d'autres bureaux ou salles de réunion sur Lyon ?<br/>Voici une sélection de Cocoons dans le 2e arrondissement et alentour.",
		},
		{
			zip: '69003',
			uriParam: '3eme-arrondissement-69003',
			latitude: 45.75826,
			longitude: 4.855386999999999,
			spaceSuggestionBody:
				"Vous recherchez d'autres bureaux ou salles de réunion sur Lyon ?<br/>Voici une sélection de Cocoons dans le 3e arrondissement et alentour.",
		},
		{
			zip: '69004',
			uriParam: '4eme-arrondissement-69004',
			latitude: 45.78095219999999,
			longitude: 4.8243491,
			spaceSuggestionBody:
				"Vous recherchez d'autres bureaux ou salles de réunion sur Lyon ?<br/>Voici une sélection de Cocoons dans le 4e arrondissement et alentour.",
		},
		{
			zip: '69005',
			uriParam: '5eme-arrondissement-69005',
			latitude: 45.7582622,
			longitude: 4.7990749,
			spaceSuggestionBody:
				"Vous recherchez d'autres bureaux ou salles de réunion sur Lyon ?<br/>Voici une sélection de Cocoons dans le 5e arrondissement et alentour.",
		},
		{
			zip: '69006',
			uriParam: '6eme-arrondissement-69006',
			latitude: 45.7751072,
			longitude: 4.8501975,
			spaceSuggestionBody:
				"Vous recherchez d'autres bureaux ou salles de réunion sur Lyon ?<br/>Voici une sélection de Cocoons dans le 6e arrondissement et alentour.",
		},
		{
			zip: '69007',
			uriParam: '7eme-arrondissement-69007',
			latitude: 45.7304251,
			longitude: 4.8399378,
			spaceSuggestionBody:
				"Vous recherchez d'autres bureaux ou salles de réunion sur Lyon ?<br/>Voici une sélection de Cocoons dans le 7e arrondissement et alentour.",
		},
		{
			zip: '69008',
			uriParam: '8eme-arrondissement-69008',
			latitude: 45.7315386,
			longitude: 4.869616499999999,
			spaceSuggestionBody:
				"Vous recherchez d'autres bureaux ou salles de réunion sur Lyon ?<br/>Voici une sélection de Cocoons dans le 8e arrondissement et alentour.",
		},
		{
			zip: '69009',
			uriParam: '9eme-arrondissement-69009',
			latitude: 45.7699424,
			longitude: 4.8037184,
			spaceSuggestionBody:
				"Vous recherchez d'autres bureaux ou salles de réunion sur Lyon ?<br/>Voici une sélection de Cocoons dans le 9e arrondissement et alentour.",
		},
	],
}
