import type { Api } from '@cocoonspace/types/shared/api'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'

import { defineEndpoint } from '../utils/define-endpoint'

export interface AddAccountUser {
	body: NonNullable<
		Api.Operations['addAccountUser']['requestBody']
	>['content']['application/json']
	response: Api.Operations['addAccountUser']['responses']['200']['content']['application/json']
}

export const addAccountUser = (
	axiosInstance: AxiosInstance,
	overideConfig?: AxiosRequestConfig,
) =>
	defineEndpoint<AddAccountUser>(
		{
			method: 'post',
			url: '/account/users',
			...overideConfig,
		},
		axiosInstance,
	)
