/* eslint-disable max-lines */
import type { Booking } from '@cocoonspace/sdk-js/types/booking'
import type { Event } from '@cocoonspace/sdk-js/types/event'
import type { Space } from '@cocoonspace/sdk-js/types/space'
import type { AnalyticsInstance } from 'analytics'
import { type PropsWithChildren, createContext, useContext } from 'react'
import { AnalyticsConsumer, AnalyticsProvider } from 'use-analytics'

import { dateTimeUtils } from '@cocoonspace/shared/utils/date-time-utils'

namespace Tracking {
	export type ClickTriggerName =
		| 'dashboard-bookASpace-spaceCard'
		| 'dashboard-bookAgain-link'
		| 'dashboard-manageUsers-link'
		| 'dashboard-seeAllCocoon-link'
		| 'dashboard-seeFavorites-link'
		| 'dashboard-seeInvoices-link'
		| 'dashboard-seeMyBookings-link'
		| 'eventCard'
		| 'eventCard-bookSame-button'
		| 'eventCard-cancelPopover-cancelBtn'
		| 'eventCard-cancelPopover-closeBtn'
		| 'eventCard-cancelPopover-confirmBtn'
		| 'eventCard-delete-button'
		| 'extra-dialog-button'
		| 'eventCard-viewInvoice-button'
		| 'favorites-empty-seeCocoons-link'
		| 'favorites-seeCocoons-link'
		| 'favorites-spaceCard'
		| 'featuredEvent-chooseMenu-button'
		| 'featuredEvent-eventCard'
		| 'featuredEvent-share-button'
		| 'landing-meetingRooms-seeAllCocoonBtn'
		| 'landing-hourlyOffices-seeAllCocoonBtn'
		| 'landing-district-seeAllCocoonBtn'
		| 'landing-district-spaceCard'
		| 'microsearch-seeCocoons'
		| 'myEvents-yearFilter-btn'
		| 'myEvents-yearFilter-monthBtn'
		| 'myEvents-yearFilter-nextBtn'
		| 'myEvents-yearFilter-prevBtn'
		| 'navbar-brand-link'
		| 'navbar-findACocoon-button'
		| 'spaceDetails-booking-days-btn'
		| 'spaceDetails-booking-hours-btn'
		| 'userMenu-logout-link'
		| 'userMenu-myAccount-link'
		| 'userMenu-myEvents-link'
		| 'userMenu-myFavorites-link'
		| 'userMenu-dashboard-link'
		| 'userMenu-myInvoices-link'
		| 'breadcrumb-link'

	export interface Dialog {
		dialogName: string
		action: 'open' | 'close'
	}

	export interface Autocomplete {
		city?: string
		postal_code?: string
		query?: string
		header?: string
		latitude?: number
		longitude?: number
	}

	export interface HomeSearch {
		capacity?: string
		text?: string
		autocompleteRes?: Autocomplete
	}

	export interface Search {
		filters: any
		resultCount?: number
		zoom: number
		capacities: ReadonlyArray<any>
	}

	export interface EventTracking {
		event: Event
		canal?: string
		platform?: string
	}

	export interface SuggestionsCardClick {
		source: string
		target: string
	}

	export interface UpdateFavorite {
		space: Pick<Space, 'favorite' | 'title'>
		route: string
	}

	export interface SuggestionsSeeMore {
		space: Pick<Space, 'title'>
	}

	export interface NavbarFindCocoonClick {
		zone: string
	}

	export interface CancelEvent {
		route: string
		event: Event
	}

	export interface ShareEvent {
		event: Event
		canal: string
		page: string
	}

	export interface BookingMissingInfos {
		isInfosPersoIncomplete: boolean
		isInfoFactuIncomplete: boolean
		hasNoPaymentMethod: boolean
	}

	export interface BookingValidated {
		event: Event
		booking: Booking
		platform: string
		transaction: string
		canal: string
	}

	export interface BookingPage {
		event: Event
		booking: Booking
	}

	export interface Extension {
		event: Event
		selectedTimeSlot: Pick<Event, 'start' | 'end'>
	}

	export interface PaymentMethodUpdate {
		action: string
		route: string
	}

	export interface ChangeEvent {
		event: Event
		eventOption: Event
		booking?: Booking
		overtime: number
	}

	export interface SearchSpaceCardClick {
		filters: any
		resultCount?: number
		zoom: number
		capacities: ReadonlyArray<any>
		type: string
	}

	export interface AccountPrefZone {
		zone?: string
		source: string
	}

	export interface RecurringOffer {
		source: 'tarifs' | 'sidebar'
		cocoon: string
		type: 'hours' | 'days'
		days?: string[]
		startDate?: string
	}

	export interface RenameEvent {
		source: 'eventDetails' | 'bookingConfirm'
	}

	export interface EventInvitation {
		source: 'eventDetails' | 'bookingConfirm' | 'shareDialog'
		guestCount: number
	}
}

const getSearchTrackingData = ({
	filters,
	resultCount,
	zoom,
	capacities,
}: {
	filters: any
	resultCount?: number
	zoom: number
	capacities: ReadonlyArray<any>
}) => {
	const capacity = capacities.find((cap) => cap.value === filters.capacity)

	const startMinutes = dateTimeUtils.utils.getTimeAsMinutes(filters.start)
	const endMinutes = dateTimeUtils.utils.getTimeAsMinutes(filters.end)

	return {
		'Days before booking': Math.ceil(
			dateTimeUtils(filters.day).diff(dateTimeUtils(), 'days', true),
		),
		'Search Date': filters.day,
		'Nb People': capacity ? capacity.label : '',
		'Zoom level': zoom,
		'Start hour': filters.start,
		'End hour': filters.end,
		Duration:
			filters.start && filters.end ? (endMinutes - startMinutes) / 60 : '',
		'Nb Results': resultCount || 0,
		Tag: filters.features?.join(', '),
		'Selected Address': filters.text,
		'Selected City': filters.autocompleteRes?.city,
		'Selected Zipcode': filters.autocompleteRes?.postal_code,
		'Typed Address': filters.autocompleteRes?.query,
		'Address type': filters.autocompleteRes?.header,
		'Address latitude': filters.autocompleteRes?.latitude,
		'Address longitude': filters.autocompleteRes?.longitude,
	}
}

// NOTE: should we use a context to store these functions instead of re-creating them each time we use the hook?
// keep in mind for potential performance issues/high memory usage
const trackingFns = (analytics: AnalyticsInstance) => {
	const _track = analytics.track

	const click = (
		triggerName: Tracking.ClickTriggerName,
		payload: Record<string, any> = {},
	) => _track('Click', { triggerName, ...payload })

	const dialog = (payload: Tracking.Dialog) => _track('Dialog', payload)

	const quickSearchBtnClick = (route: string) =>
		_track('Quick Search See Cocoons Btn Click', {
			route,
		})

	const homeSearch = (payload: Tracking.HomeSearch) =>
		_track('HomeSearch', {
			'Nb People': payload.capacity,
			'Selected Address': payload.text,
			'Selected City': payload.autocompleteRes?.city,
			'Selected Zipcode': payload.autocompleteRes?.postal_code,
			'Typed Address': payload.autocompleteRes?.query,
			'Address type': payload.autocompleteRes?.header,
			'Address latitude': payload.autocompleteRes?.latitude,
			'Address longitude': payload.autocompleteRes?.longitude,
		})

	const landingPage = () => _track('landing Page')

	const eventEditButtonClick = (target: string) =>
		_track('Event edit button click', { target })

	const search = (payload: Tracking.Search) =>
		_track('Search', getSearchTrackingData(payload))

	const accountPrefZone = (payload: Tracking.AccountPrefZone) =>
		_track('Account pref zone', payload)

	const accountPrefHideCodes = (hideCodes: boolean) =>
		_track('Account pref hide codes', { default_hide_codes: hideCodes })

	const accountPrefNewsletter = (newsletter: boolean) =>
		_track('Account pref newsletter', { newsletter })

	const personalInfosUpdate = (route: string) =>
		_track('Personal infos update', { route })

	const billingAddressUpdate = (route: string) =>
		_track('Billing address update', { route })

	const addToCalendarDialog = () => _track('Add to calendar dialog')

	const event = ({ event, canal, platform }: Tracking.EventTracking) =>
		_track('Event', {
			Place: event.title,
			'Event date': event.day,
			'Start hour': event.start,
			'End hour': event.end,
			Duration: (event.duration || 0) / 60,
			'Booking ID': event.booking_id,
			Capacity: event.space?.capacity,
			Canal: canal,
			Platforme: platform,
			User: event.is_organizer ? 'Organizer' : 'Guest',
		})

	const spaceSuggestionsCardClick = (payload: Tracking.SuggestionsCardClick) =>
		_track('Space suggestions card click', payload)

	const showMapOrList = (value: boolean) =>
		_track(value ? 'Show map' : 'Show list')

	const cocoonPage = (Place?: string) => _track('Cocoon page', { Place })

	const renameEvent = (payload: Tracking.RenameEvent) =>
		_track('Rename event', payload)

	const updateFavorite = ({ space, route }: Tracking.UpdateFavorite) =>
		_track(`Favorite ${!space.favorite ? 'added' : 'deleted'}`, {
			Place: space.title,
			Page: route === '/[zone]' ? 'Search' : 'MyFavorites',
		})

	const spaceSuggestionsSeeMore = ({ space }: Tracking.SuggestionsSeeMore) =>
		_track('Space suggestions see more click', { source: space.title })

	const navbarFindCocoonClick = ({ zone }: Tracking.NavbarFindCocoonClick) =>
		_track('Navbar Find Cocoon Btn Click', { zone })

	const cancelEvent = (payload: Tracking.CancelEvent) =>
		_track('Cancel event', {
			Page: payload.route === '/my-events' ? 'My Events' : 'Event',
			Place: payload.event.space?.title,
			'Booking date': payload.event.booked_at,
			'Event date': payload.event.day,
			'Start hour': payload.event.start,
			'End hour': payload.event.end,
			Duration: payload.event.duration,
			'Place price': (payload.event.space_price || 0) / 100,
			'Service price': (payload.event.extras_price || 0) / 100,
			'Total price': (payload.event.total_price || 0) / 100,
			'Booking ID': payload.event.booking_id,
			Capacity: payload.event.space?.capacity,
			Canal: 'Web direct',
			Plateforme: 'Cocoon',
			User: payload.event.is_organizer ? 'Organizer' : 'Guest',
		})

	const seeMaps = (Page: string) => _track('See maps', { Page })

	const timePickerSlotDrag = () => _track('Time picker slot drag')

	const timePickerSelectClick = () => _track('Time picker select click')

	const calendarShare = (target: string) => _track('Calendar Share', { target })

	const invitationButtonClick = (target: string) =>
		_track('Invitation button click', { target })

	const shareEvent = ({ event, canal, page }: Tracking.ShareEvent) =>
		_track('Share event', {
			'Booking ID': event.booking_id,
			Place: event.space?.title,
			'Canal Of Share': canal,
			Page: page,
		})

	const bookingMissingInfos = (payload: Tracking.BookingMissingInfos) =>
		_track('bookingMissingInfos', payload)

	const bookingValidated = ({
		event,
		booking,
		platform,
		canal,
		transaction,
	}: Tracking.BookingValidated) => {
		const daysBeforeBooking =
			dateTimeUtils(event.day).diff(dateTimeUtils(), 'day') + 1

		return _track('Booking Validated', {
			Plateforme: platform,
			Favorite: event?.space?.favorite ? 'true' : 'false',
			'Days before booking': daysBeforeBooking,
			'Booking date': booking.created_at,
			'Event date': event.day,
			'Start hour': event.start,
			'End hour': event.end,
			Duration: event.duration,
			Place: event.space?.title,
			Capacity: event.space?.capacity,
			'Hour price': (event.price_hour || 0) / 100,
			'Place price': (booking.space_price || 0) / 100,
			'Service price': (booking.extras_price || 0) / 100,
			'Total price': (booking.total_price || 0) / 100,
			'Booking ID': booking.id,
			Service: event.extras && event.extras.map((extra) => extra.id),
			Transaction: transaction,
			Canal: canal,
			Pricing: event.rebates && event.rebates?.[0]?.code,
			type: event.type,
			nth: event.nth,
		})
	}

	const bookingPage = ({ event, booking }: Tracking.BookingPage) => {
		const eventDate = new Date(event.day!)

		const daysBeforeBooking =
			dateTimeUtils(event.day).diff(dateTimeUtils(), 'day') + 1

		return _track('Booking page', {
			Favorite: event?.space?.favorite ? 'true' : 'false',
			'Days before booking': daysBeforeBooking,
			'Booking date': event.booked_at,
			'Event date': eventDate,
			'Start hour': event.start,
			'End hour': event.end,
			Duration: event.duration ? event.duration / 60 : undefined,
			Place: event.space?.title,
			'Hour price': event.price_hour ? event.price_hour / 100 : undefined,
			'Place price': booking.space_price,
			'Service price': booking.extras_price
				? booking.extras_price / 100
				: undefined,
			'Total price': booking.total_price / 100,
			type: event.type,
			nth: event.nth,
		})
	}

	const extension = ({ event, selectedTimeSlot }: Tracking.Extension) =>
		_track('Extension', {
			Place: event.space?.title,
			Capacity: event.space?.capacity,
			'Duration new': (event.duration || 0) / 60,
			'Duration extension':
				(dateTimeUtils.utils.getTimeAsMinutes(event.start) -
					dateTimeUtils.utils.getTimeAsMinutes(event.end) -
					(dateTimeUtils.utils.getTimeAsMinutes(
						selectedTimeSlot.start || event.start,
					) -
						dateTimeUtils.utils.getTimeAsMinutes(
							selectedTimeSlot.end || event.end,
						))) /
				60,
		})

	const paymentMethodUpdate = ({
		action,
		route,
	}: Tracking.PaymentMethodUpdate) =>
		_track('Payment method update', { action, route })

	const changeEvent = ({
		event,
		eventOption,
		booking,
		overtime,
	}: Tracking.ChangeEvent) =>
		_track('Change event', {
			Place: event.space?.title,
			'Start hour': eventOption.start,
			'End hour': eventOption.end,
			'Booking date': booking?.created_at,
			'Event date': eventOption.day,
			'Event date old': new Date(event.day!),
			Duration: overtime + event.duration! / 60,
			'Place price': booking?.space_price! / 100,
			'Service price': booking?.extras_price! / 100,
			'Total price': booking?.total_price
				? booking?.total_price / 100
				: undefined,
			'Price change': -(booking?.balance! / 100),
			'Booking ID': booking?.id,
			Capacity: event.space?.capacity,
			Canal: 'Web direct',
			Plateforme: 'Cocoon',
			User: event.is_organizer ? 'Organizer' : 'Guest',
		})

	const searchSpaceCardClick = ({
		type,
		...payload
	}: Tracking.SearchSpaceCardClick) =>
		_track('Search Space Card Click', {
			...getSearchTrackingData(payload),
			'Occupancy Rate Feature': type === 'alternative',
		})

	const updateEmail = () => _track('Email update')

	const updatePassword = () => _track('Password update')

	const recurringOffer = (payload: Tracking.RecurringOffer) =>
		_track('Resa recurrente', payload)

	const eventInvitation = (payload: Tracking.EventInvitation) =>
		_track('eventInvitation', payload)

	const eventExtrasUpdate = () => _track('eventExtrasUpdate')

	const searchAutocompleteSelect = (payload: {
		category: string
		text: string
	}) => _track('searchAutocompleteSelect', payload)

	const track = {
		click,
		dialog,
		accountPrefHideCodes,
		accountPrefNewsletter,
		accountPrefZone,
		addToCalendarDialog,
		billingAddressUpdate,
		bookingPage,
		bookingMissingInfos,
		bookingValidated,
		calendarShare,
		cancelEvent,
		changeEvent,
		cocoonPage,
		event,
		eventEditButtonClick,
		eventExtrasUpdate,
		eventInvitation,
		extension,
		homeSearch,
		invitationButtonClick,
		landingPage,
		navbarFindCocoonClick,
		paymentMethodUpdate,
		personalInfosUpdate,
		quickSearchBtnClick,
		recurringOffer,
		renameEvent,
		search,
		searchSpaceCardClick,
		seeMaps,
		shareEvent,
		showMapOrList,
		spaceSuggestionsCardClick,
		spaceSuggestionsSeeMore,
		timePickerSelectClick,
		timePickerSlotDrag,
		updateEmail,
		updateFavorite,
		updatePassword,
		searchAutocompleteSelect,
	}

	return {
		track,
		analytics,
	}
}

export const trackingContext = createContext(
	{} as ReturnType<typeof trackingFns>,
)

interface TrackingProviderProps {
	instance: AnalyticsInstance
}

export const useTracking = () => useContext(trackingContext)

export const TrackingProvider = ({
	instance,
	children,
}: PropsWithChildren<TrackingProviderProps>) => {
	return (
		<AnalyticsProvider instance={instance}>
			<AnalyticsConsumer>
				{(analytics) => (
					<trackingContext.Provider value={trackingFns(analytics)}>
						{children}
					</trackingContext.Provider>
				)}
			</AnalyticsConsumer>
		</AnalyticsProvider>
	)
}
