import type { Api } from '@cocoonspace/types/shared/api'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'

import { defineEndpoint } from '../utils/define-endpoint'

export interface GetAccountUsers {
	response: Api.Operations['getAccountUsers']['responses']['200']['content']['application/json']
}

export const getAccountUsers = (
	axiosInstance: AxiosInstance,
	overideConfig?: AxiosRequestConfig,
) =>
	defineEndpoint<GetAccountUsers>(
		{
			method: 'get',
			url: '/account/users',
			...overideConfig,
		},
		axiosInstance,
	)
