import { SvgHourglass } from '@cocoonspace/icons/react/font-awesome/light/hourglass'
import { SvgHourglassClock } from '@cocoonspace/icons/react/font-awesome/light/hourglass-clock'
import type { Event } from '@cocoonspace/sdk-js/types/event'
import { bookingConfig } from '@cocoonspace/shared/config/booking-config'
import { useEmitter } from '@cocoonspace/shared/hooks/use-emitter.hook'
import { dateTimeUtils } from '@cocoonspace/shared/utils/date-time-utils'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Button } from '~/components/ui/button'
import { Dialog, DialogContent } from '~/components/ui/dialog'
import { expirationEmitter } from '~/domains/booking/emitters/expiration-dialog.emitter'

export const ExpirationDialog = ({
	mode,
}: {
	mode: 'booking' | 'extras'
}) => {
	const [event, setEvent] = useState<Event | null>(null)

	const { t } = useTranslation()
	const router = useRouter()
	const intervalRef = useRef<number>()

	const [isOpen, setIsOpen] = useState(false)
	const [isExpired, setIsExpired] = useState(false)
	const [isDismissed, setIsDissmissed] = useState(false)

	const handleExpirationStart = useCallback(
		(event: Event) => {
			setEvent(event)

			window.clearInterval(intervalRef.current)

			const warningTimeout = dateTimeUtils(event.expires_at).subtract(
				bookingConfig.warningTimeout,
				'minutes',
			)

			intervalRef.current = window.setInterval(() => {
				const now = dateTimeUtils()

				if (now.isSame(event.expires_at) || now.isAfter(event.expires_at)) {
					setIsExpired(true)
					setIsDissmissed(false)
					setIsOpen(true)
				} else if (!isDismissed && now.isAfter(warningTimeout)) {
					setIsOpen(true)
				}
			}, 1000)
		},
		[isDismissed],
	)

	const handleExpirationReset = useCallback(() => {
		window.clearInterval(intervalRef.current)

		setIsOpen(false)
		setIsExpired(false)
		setIsDissmissed(false)
	}, [])

	useEffect(() => {
		return () => {
			window.clearInterval(intervalRef.current)
		}
	}, [])

	useEffect(() => {
		const handleRouteChangeComplete = (url: string) => {
			if (!/^\/booking/.test(url)) {
				handleExpirationReset()
			}
		}

		router.events.on('routeChangeComplete', handleRouteChangeComplete)

		return () => {
			router.events.off('routeChangeComplete', handleRouteChangeComplete)
		}
	}, [handleExpirationReset, router.events])

	useEmitter(
		expirationEmitter,
		{
			'expiration.reset': handleExpirationReset,
			'expiration.start': handleExpirationStart,
		},
		[handleExpirationStart, handleExpirationReset],
	)

	if (!event) {
		return null
	}

	const title = isExpired
		? t(`booking:expirationDialog.${mode}.expired.title`)
		: t(`booking:expirationDialog.${mode}.firstAnnoucement.title`, {
				time: dateTimeUtils(event.expires_at).fromNow(),
			})

	const subtitle = isExpired
		? t(`booking:expirationDialog.${mode}.expired.body`)
		: t(`booking:expirationDialog.${mode}.firstAnnoucement.body`)

	const buttonLabel = isExpired
		? t(`booking:expirationDialog.${mode}.expired.button`)
		: t(`booking:expirationDialog.${mode}.firstAnnoucement.button`)

	return (
		<Dialog
			open={isOpen && !isDismissed}
			onOpenChange={(isOpen) => {
				if (!isExpired && !isOpen) {
					setIsDissmissed(true)
				}
			}}
		>
			<DialogContent
				hideCloseBtn={isExpired}
				className='max-w-[450px]'
			>
				<div className='flex flex-col items-center gap-8 p-4'>
					<div className='flex items-center gap-4'>
						{isExpired ? (
							<SvgHourglass className='text-[50px] text-primary' />
						) : (
							<SvgHourglassClock className='text-[50px] text-primary' />
						)}

						<span className='font-bold text-xl'>{title}</span>
					</div>

					<span className='text-center'>{subtitle}</span>

					<Button
						className='w-full'
						onClick={() => {
							isExpired
								? router.push({
										pathname: '/[zone]/[slug]',
										query: { zone: event.space?.zone, slug: event.space?.slug },
									})
								: setIsDissmissed(true)
						}}
					>
						{buttonLabel}
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	)
}
