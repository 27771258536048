import * as Sentry from '@sentry/nextjs'

export const onErrorInterceptor = (err: any) => {
	console.info('API ERROR: ', err) // eslint-disable-line

	if (err.response?.status !== 401) {
		Sentry.captureException(err, {
			contexts: {
				api: {
					requestId: err.response?.headers?.['request-id'],
					message: err.message,
				},
			},
		})
	}

	return Promise.reject(err)
}
