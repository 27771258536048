import type { Api } from '@cocoonspace/types/shared/api'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'

import { defineEndpoint } from '../utils/define-endpoint'

export interface UpdateEventOrganizer {
	uriParams: Api.Operations['updateEventOrganizer']['parameters']['path']
	body: NonNullable<
		Api.Operations['updateEventOrganizer']['requestBody']
	>['content']['application/json']
	response: Api.Operations['updateEventOrganizer']['responses']['200']['content']['application/json']
}

export const updateEventOrganizer = (
	axiosInstance: AxiosInstance,
	overideConfig?: AxiosRequestConfig,
) =>
	defineEndpoint<UpdateEventOrganizer>(
		{
			method: 'put',
			url: '/events/{eventId}/organizer',
			...overideConfig,
		},
		axiosInstance,
	)
