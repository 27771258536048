import type { Api } from '@cocoonspace/types/shared/api'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'

import { defineEndpoint } from '../utils/define-endpoint'

export interface AddFavorite {
	uriParams: Api.Operations['checkBookingPayment']['parameters']['path']
	response: Api.Operations['checkBookingPayment']['responses']['200']['content']['application/json']
}

export const checkBookingPayment = (
	axiosInstance: AxiosInstance,
	overideConfig?: AxiosRequestConfig,
) =>
	defineEndpoint<AddFavorite>(
		{
			method: 'get',
			url: '/bookings/{bookingId}/status/{tx}',
			...overideConfig,
		},
		axiosInstance,
	)
