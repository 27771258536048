import type { AxiosInstance } from 'axios'

import { deleteMe } from '../endpoints/delete-me'
import { addFavorite } from './../endpoints/add-favorite'
import { getMe } from './../endpoints/get-me'
import { getUser } from './../endpoints/get-user'
import { removeFavorite } from './../endpoints/remove-favorite'
import { updateMe } from './../endpoints/update-me'

export const usersModule = (axiosInstance: AxiosInstance) => ({
	me: getMe(axiosInstance),
	getOne: getUser(axiosInstance),
	deleteMe: deleteMe(axiosInstance),
	updateMe: updateMe(axiosInstance),
	addToFavorites: addFavorite(axiosInstance),
	removeFromFavorites: removeFavorite(axiosInstance),
})
