import type { Api } from '@cocoonspace/types/shared/api'
import type { AxiosInstance } from 'axios'

import { defineEndpoint } from './../utils/define-endpoint'

export interface AutocompleteGeocodeGetAll {
	params: Api.Operations['geocode']['parameters']['query']
	response: {
		data: Api.Geocode
	}
}

export interface GetAllAutocomplete {
	params: Api.Operations['autocomplete']['parameters']['query']
	response: { data: Api.Autocomplete }
}

export const autocompleteModule = (axiosInstance: AxiosInstance) => {
	return {
		getAll: defineEndpoint<GetAllAutocomplete>(
			{ method: 'get', url: '/autocomplete' },
			axiosInstance,
		),
		getAllGeocode: defineEndpoint<AutocompleteGeocodeGetAll>(
			{ method: 'get', url: '/autocomplete/geocode' },
			axiosInstance,
		),
	}
}
