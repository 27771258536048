import { useCallback, useSyncExternalStore } from 'react'

type MediaQuery = `(${string}:${string})`

const getSnapshot = (query: MediaQuery) => {
	return window.matchMedia(query)?.matches
}

const subscribe = (onChange: VoidFunction, query: MediaQuery) => {
	const mql = window.matchMedia(query)

	mql?.addEventListener('change', onChange)

	return () => {
		mql?.removeEventListener('change', onChange)
	}
}

export const useMediaQuery = (query: MediaQuery, defaultValue = false) => {
	const subscribeMediaQuery = useCallback(
		(onChange: VoidFunction) => {
			return subscribe(onChange, query)
		},
		[query],
	)

	const matches = useSyncExternalStore(
		subscribeMediaQuery,
		() => getSnapshot(query),
		() => defaultValue,
	)

	return matches
}
