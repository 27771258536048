import type { Api } from '@cocoonspace/types/shared/api'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'

import { defineEndpoint } from '../utils/define-endpoint'

export interface GetSpace {
	uriParams: Api.Operations['getSpace']['parameters']['path']
	params?: Api.Operations['getSpace']['parameters']['query']
	response: Api.Operations['getSpace']['responses']['200']['content']['application/json']
}

export const getSpace = (
	axiosInstance: AxiosInstance,
	overideConfig?: AxiosRequestConfig,
) =>
	defineEndpoint<GetSpace>(
		{
			method: 'get',
			url: '/spaces/{spaceId}',
			...overideConfig,
		},
		axiosInstance,
	)
