import type { Api } from '@cocoonspace/types/shared/api'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'

import { defineEndpoint } from '../utils/define-endpoint'

export interface GetSpaceCalendar {
	uriParams: Api.Operations['getSpaceCalendar']['parameters']['path']
	params?: Api.Operations['getSpaceCalendar']['parameters']['query']
	response: Api.Operations['getSpaceCalendar']['responses']['200']['content']['application/json']
}

export const getSpaceCalendar = (
	axiosInstance: AxiosInstance,
	overideConfig?: AxiosRequestConfig,
) =>
	defineEndpoint<GetSpaceCalendar>(
		{
			method: 'get',
			url: '/spaces/{spaceId}/calendar',
			...overideConfig,
		},
		axiosInstance,
	)
