import type { ReactNode } from 'react'
import { create } from 'zustand'

export interface InitialData {
	email: string
}

export type ActiveTab = 'login' | 'register' | 'forgottenPass'

export interface OpenDialogPayload {
	activeTab?: ActiveTab
	initialData?: InitialData
	errorMsg?: ReactNode
}

type AuthDialogStore = {
	isAuthDialogOpen: boolean
	activeTab: ActiveTab
	initialData?: InitialData
	errorMsg?: ReactNode

	computed: {
		title: string
	}

	setErrorMsg: (errorMsg: string) => void
	setActiveTab: (activeTab: ActiveTab) => void
	openDialog: (payload?: OpenDialogPayload) => void
	closeDialog: () => void
}

const titleRefs: Record<ActiveTab, string> = {
	login: 'auth:login.title',
	register: 'auth:register.title',
	forgottenPass: 'auth:recover.title',
}

export const useAuthDialogStore = create<AuthDialogStore>()((set, get) => ({
	activeTab: 'login',
	isAuthDialogOpen: false,
	errorMsg: undefined,
	initialData: { email: '' },

	computed: {
		get title() {
			return titleRefs[get().activeTab]
		},
	},

	setActiveTab: (activeTab: ActiveTab) => set(() => ({ activeTab })),
	openDialog: (payload) =>
		set(() => ({
			isAuthDialogOpen: true,
			activeTab: payload?.activeTab || 'login',
			initialData: payload?.initialData,
			errorMsg: payload?.errorMsg,
		})),
	closeDialog: () =>
		set(() => ({
			isAuthDialogOpen: false,
			activeTab: 'login',
			errorMsg: undefined,
		})),
	setErrorMsg: (errorMsg: string) => set(() => ({ errorMsg })),
}))
